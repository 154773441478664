import React from "react";
import {NormalLayout} from "../../../../components/layouts/normal_layout";

const Hyperion1000: React.FC = () => {

  return (
      <NormalLayout type="product">
        {/*<Section1 />*/}
        {/*<Section2 />*/}
        {/*<Section3 />*/}
        {/*<Section4 />*/}
        {/*<Section5 />*/}
        {/*<Section6 />*/}
      </NormalLayout>
  )
}

export default Hyperion1000;